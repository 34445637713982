import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core";

function AwakenerAcademy(props) {
    const classes = useStyles()
    useEffect(() => {
        window.addEventListener('scroll', ev => {
            const el = document.getElementById('awkenerAcademy')
            if (!el) return;
            const total = el?.offsetHeight
            const start = el?.offsetTop
            const screenHeight = window.innerHeight
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight
                if (scrollItem > scrollValid) {
                    return;
                }
                const percent = Math.round(scrollItem / scrollValid * 100)
                const content = el.querySelector("#awkenerAcademyContent")
                const content1 = el.querySelector("#awkenerAcademyContent1")
                const content2 = el.querySelector("#awkenerAcademyContent2")
                if (percent >= 0 && percent <= 30) {
                    content1.style.display = "block"
                    content.style.display = "none"
                    content2.style.display = "none"
                    return;
                }
                if (percent > 30 && percent <= 60) {
                    content1.style.display = "none"
                    content.style.display = "none"
                    content2.style.display = "block"
                    return;
                }

                if (percent >= 60) {
                    content1.style.display = "none"
                    content.style.display = "block"
                    content2.style.display = "block"
                    content.style.opacity = 1
                    return;
                }
            }
        })
        return () => {
            window.removeEventListener("scroll", ev => {
                console.log('unmount')
            })
        }
    }, [])
    return (
        <section className={classes.root} id={"awkenerAcademy"}>
            <div className={classes.boxSticky}>
                <div className={classes.content} id={"awkenerAcademyContent"}>
                    <div className={"fadeInBottom"}>
                        <div className={classes.title1}>Awakener Academy</div>
                        <div className={classes.title2}>コーデミア</div>
                    </div>
                </div>
                <div className={classes.content1} id={"awkenerAcademyContent1"}>
                    <div className={"fadeInBottom"}>
                        Metaverse×University
                    </div>
                </div>
                <div className={classes.content2} id={"awkenerAcademyContent2"}>
                    <div className={"fadeInBottom"}>
                        Metaversity
                    </div>
                </div>
                <img loading="lazy" className={classes.img} src="/images/laudai.png" alt=""/>
            </div>
        </section>
    );
}

export default AwakenerAcademy;
const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        height: "600vh",
        zIndex: 1,
        backgroundColor: "#fff",
        marginTop: "-150vh"
    },
    boxSticky: {
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
        overflow: "hidden"

    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    content: {
        position: "absolute",
        left: 0,
        right: 0,
        textAlign: "center",
        top: "50%",
        transform: "translateY(-50%)",
        display: "none",
    },
    title1: {
        fontSize: "80pt",
        fontWeight: 900,
        WebkitTextStrokeWidth: "3px",
        WebkitTextStrokeColor: "#fff",
        [theme.breakpoints.down('md')]: {
            fontSize: "50pt",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "35pt",
            WebkitTextStrokeWidth: "2px",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "25pt",
        },
    },
    title2: {
        fontSize: "90pt",
        fontWeight: 900,
        WebkitTextStrokeWidth: "3px",
        WebkitTextStrokeColor: "#fff",
        [theme.breakpoints.down('md')]: {
            fontSize: "60pt",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "45pt",
            WebkitTextStrokeWidth: "2px",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "30pt",
        },
    },
    content1: {
        position: "absolute",
        bottom: "37px !important",
        left: 0,
        right: 0,
        color: "#fff",
        fontSize: "80pt",
        display: "none",
        [theme.breakpoints.down('md')]: {
            fontSize: "50pt !important",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "35pt !important",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "25pt !important",
        },
    },
    content2: {
        position: "absolute",
        bottom: "37px !important",
        left: 0,
        right: 0,
        color: "#fff",
        fontSize: "80pt",
        display: "none",
        [theme.breakpoints.down('md')]: {
            fontSize: "50pt !important",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "35pt !important",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "25pt !important",
        },

    }


}))