import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";

function Instruction(props) {
    const classes = useStyles()
    return (
        <section className={classes.root}>
            {/*<h2 className={classes.title}><span>オンライン1on1指導 160分×12回</span></h2>*/}
            <div className={classes.sticky}>
                <h2 className={classes.title}><span>オンライン1on1指導 160分×12回</span></h2>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className={classes.item}>
                            <h3 className={classes.titleItem}>➀1on1セッション</h3>
                            <h4 className={classes.subTitleItem}>160分×6回</h4>
                            <div className={classes.img}>
                                <img loading="lazy" src="/images/imgYou.svg" alt=""/>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className={classes.item}>
                            <h3 className={classes.titleItem}>➁1on1フィードバック</h3>
                            <h4 className={classes.subTitleItem}>160分×6回</h4>
                            <div className={classes.img}>
                                <img loading="lazy" src="/images/imgClient.svg" alt=""/>
                            </div>

                        </div>
                    </Grid>
                </Grid>
                <p className={classes.lineText}>インサイトマップ・ファシリテータのプロフェッショナルから、</p>
                <p className={classes.lineText}>➀1on1セッションを受け、質疑応答ができます。</p>
                <p className={classes.lineText}>➁クライアントに提供した1on1セッションの録画データを見ながら、</p>
                <p className={classes.lineText}> 技術的なフィードバックを個別に受けることができます。</p>
                <p className={classes.lineText}>※プロフェッショナルと個別に日程調整をいつでも1on1を実施できます。</p>
                <p className={classes.lineText}>（有効期限：お申し込み直後～講座の開始から6ヶ月以内）</p>
            </div>
        </section>
    );
}

export default Instruction;
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#f1f1f1",
        margin: "20px 0",
        padding: "0px 20px 30px 20px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative",
        boxSizing: "border-box",
        height: "250vh",
        [theme.breakpoints.down('sm')]: {
            minHeight: "unset",
            height: "unset"
        }
    },
    sticky: {
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        height: "100vh",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            position: "static"
        },
    },
    title: {
        margin: 0,
        textAlign: "center",
        fontSize: "36px",
        fontWeight: 900,
        marginBottom: 50,
        // position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        "& span": {
            backgroundColor: " #fff",
            padding: "0 60px 20px 60px",
            borderRadius: "0 0 25px 25px",
            display: "inline-block",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            "& span": {
                padding: "0 25px 10px 25px",
                borderRadius: "0 0 15px 15px",
            },
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        }
    },
    item: {
        maxWidth: 700,
        margin: "0 auto 40px auto",
        textAlign: "center",
        padding: "20px 0 50px 0",
        boxSizing: "border-box",
        backgroundColor: "#fff",
        borderRadius: "30px",
        [theme.breakpoints.down('sm')]: {
            margin: "0 auto 15px auto",
            padding: "20px 0 30px 0",
        }
    },
    titleItem: {
        margin: "0 auto 0px auto",
        fontSize: "36px",
        fontWeight: 900,
        color: "#535353",
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
        }
    },
    subTitleItem: {
        margin: "0 auto 20px auto",
        fontSize: "30px",
        fontWeight: 900,
        color: "#535353",
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        }

    },
    lineText: {
        margin: 0,
        fontSize: "25px",
        textAlign: "center",
        fontWeight: 900,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        }
    },
    img: {
        position: "relative",
        paddingTop: "50%",
        width: "80%",
        margin: "auto",
        "& img": {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            objectFit: "contain",
        },
    }


}))