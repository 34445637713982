import React, {useEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom'
import Home from "./components/Home";
import './globals.css';
import './App.css';

function App() {

    const history = useHistory()
    useEffect(() => {
        if (window.location.pathname !== "/cordemia/services") {
            history.push("/cordemia/services")
        }
    }, [])
    return (
        <div className="App">
            <Switch>
                <Route path={"/cordemia/services"} component={Home}/>
                <Route path={"/"} component={Home}/>
            </Switch>
        </div>
    );
}

export default App;