import React, {useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/core";
import {useMediaWidth} from "../../context/mediaQuery";

const EarthDatas = [
    {
        id: 1,
        image: '/images/part2/1.png',
        textTop: null,
        textSub: null,
        textBottom1: null,
        textBottom2: null,
        textBottom3: null,
        textBottom4: null,
        textBottom5: null,
        textBottom6: null,
    },
    {
        id: 2,
        image: '/images/part2/2.png',
        textTop: '人類の創造性が覚醒するメタバース',
        textSub: 'ジニアース',
        textBottom1: 'Geniearth',
        textBottom2: null,
        textBottom3: null,
        textBottom4: null,
        textBottom5: null,
        textBottom6: null,
    },
    {
        id: 3,
        image: '/images/part2/3.png',
        textTop: 'Identity',
        textSub: null,
        textBottom1: 'ジニアースでは、',
        textBottom2: '何者でも選べる。',
        textBottom3: 'ありのままの私もよし。',
        textBottom4: 'なりたい私を目指すもよし。',
        textBottom5: 'いろんな私を楽しむもよし。',
        textBottom6: null,
    },
    {
        id: 4,
        image: '/images/part2/4.png',
        textTop: 'Contents',
        textSub: null,
        textBottom1: '創造性の原点である',
        textBottom2: '「問うチカラ」を磨く。',
        textBottom3: '「何者として、',
        textBottom4: 'どこへ向かうのか？」',
        textBottom5: 'そして、問いを問い、',
        textBottom6: '問う技術の深淵へ。',
    },
    {
        id: 5,
        image: '/images/part2/5.png',
        textTop: 'AI Mentor',
        textSub: null,
        textBottom1: '様々な問いモデルを',
        textBottom2: '実装したAIメンターと',
        textBottom3: 'いつでも、どこでも',
        textBottom4: '内観し、創造できる。',
        textBottom5: '自己決定や時間管理も',
        textBottom6: 'サポートしてくれる。',
    },
    {
        id: 6,
        image: '/images/part2/6.png',
        textTop: 'Skill',
        textSub: null,
        textBottom1: '人材開発のプロから',
        textBottom2: '1on1やワークショップの',
        textBottom3: '理論と技術を学べる。',
        textBottom4: '自分を開花させるもよし、',
        textBottom5: '他者を支援するもよし。',
        textBottom6: null,
    },
    {
        id: 7,
        image: '/images/part2/7.png',
        textTop: 'Economy',
        textSub: null,
        textBottom1: '学んだ理論と磨いた技術',
        textBottom2: 'を使って、オンライン上の',
        textBottom3: '1on1＆グループワーク',
        textBottom4: 'プラットフォームで',
        textBottom5: '仕事ができる。（試験あり）',
        textBottom6: null,
    },
    {
        id: 8,
        image: '/images/part2/8.png',
        textTop: 'Univesity',
        textSub: null,
        textBottom1: '人の可能性開きたい。',
        textBottom2: 'そんな想いを持った人が、',
        textBottom3: '本質的で、統合的な',
        textBottom4: '人材開発の理論と技術を',
        textBottom5: '磨き合えるメタバース内大学。',
        textBottom6: null,
    },
    {
        id: 9,
        image: '/images/part2/9.png',
        textTop: "３つの世界を旅する。",
        textSub: null,
        textBottom1: "メタバースで問答し、",
        textBottom2: "インナーバースを開拓し、",
        textBottom3: "リアルバースを変革する。",
        textBottom4: null,
        textBottom5: null,
        textBottom6: null,
        textAbsolute11: "メタバース",
        textAbsolute12: "(仮想世界)",
        textAbsolute21: "インナーバース",
        textAbsolute22: "(内的世界)",
        textAbsolute31: "リアルバース",
        textAbsolute32: "(現実世界)",
    },
    {
        id: 10,
        image: '/images/part2/10.png',
        textTop: null,
        textSub: null,
        textBottom1: null,
        textBottom2: null,
        textBottom3: null,
        textBottom4: null,
        textBottom5: null,
        textBottom6: null,
    },

]

function TextTop(props) {
    const classes = useStyles()
    const width = useMediaWidth();
    const ref = useRef(0)
    // const [step, setStep] = useState(0)
    //
    // const changeStep = useCallback((value) => {
    //     setStep(value)
    // })

    const play = () => {
        if (window.scrollY !== 0)
            return;
        const video = document.getElementById('videoEarthTop')
        video.style.display = "block"
        const interval = setInterval(() => {
            if (window.scrollY !== 0) {
                if (interval)
                    clearInterval(interval)
                return;
            }
            if (ref.current < 55) {
                ref.current = ref.current + 1;
                const percent = ref.current
                const el = document.getElementById('textVideo')
                if (!el) return;
                const text = el.querySelector("#scaleText")
                const textAwaken = el.querySelector("#textAwaken")
                const contentTop = el.querySelector("#contentTop")
                if (percent <= 15) {
                    text.style.transform = "scale(1)"
                    textAwaken.style.display = "flex"
                    // contentTop.style.display = "none"
                    return;
                }
                if (percent > 15 && percent <= 40) {
                    let scale = `scale(${(percent - 15) * 2}) translateY(10%)`
                    text.style.transform = scale
                    // textAwaken.style.display = "flex"
                    // contentTop.style.display = "none"
                    return;
                }
                if (percent > 40) {
                    text.style.display = "none"
                    textAwaken.style.display = "none"
                    contentTop.style.display = "block"
                    return;
                }
            } else {
                clearInterval(interval)
            }
        }, 50)

    }

    useEffect(() => {
        // play()
        window.addEventListener('scroll', ev => {
            const el = document.getElementById('textVideo')
            if (!el) return;
            let total = el?.offsetHeight
            let start = el?.offsetTop
            const screenHeight = window.innerHeight

            // const content = el.querySelector("#insight-map-tree-content")
            // start = start + screenHeight
            // total = total - screenHeight
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight
                if (scrollItem > scrollValid) {
                    return;
                }
                const percent = Math.round(scrollItem / scrollValid * 100)
                const video = el.querySelector("#videoEarthTop");
                const content1 = el.querySelector("#earth-content-1");
                const content2 = el.querySelector("#earth-content-2");
                const textContent2 = el.querySelector("#earth-textContent-2");
                const content3 = el.querySelector("#earth-content-3");
                const textContent3 = el.querySelector("#earth-textContent-3");
                const content4 = el.querySelector("#earth-content-4");
                const textContent4 = el.querySelector("#earth-textContent-4");
                const content5 = el.querySelector("#earth-content-5");
                const textContent5 = el.querySelector("#earth-textContent-5");
                const content6 = el.querySelector("#earth-content-6");
                const textContent6 = el.querySelector("#earth-textContent-6");
                const content7 = el.querySelector("#earth-content-7");
                const textContent7 = el.querySelector("#earth-textContent-7");
                const content8 = el.querySelector("#earth-content-8");
                const textContent8 = el.querySelector("#earth-textContent-8");
                const content9 = el.querySelector("#earth-content-9");
                const textContent9 = el.querySelector("#earth-textContent-9");
                const content10 = el.querySelector("#earth-content-10");
                const content = el.querySelector("#contentTop");
                const textAwaken = el.querySelector("#textAwaken")
                // console.log(percent);
                if (percent >= 0 && percent < 7) {
                    video.style.display = "block"
                    content.style.display = "block"
                    content1.style.display = "none"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "none"
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent >= 7 && percent < 14) {
                    video.style.display = "block"
                    content.style.display = "block"
                    content1.style.display = "none"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "none"
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent >= 14 && percent < 21) {
                    let opcity1 = (percent / 14) - 0.5
                    let opcity2 = (-percent / 14) + 2
                    video.style.opacity = opcity2
                    video.style.display = "block"
                    content.style.display = "none"
                    content1.style.opacity = opcity1
                    content1.style.display = "flex"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "none"
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent >= 21 && percent < 28) {
                    video.style.display = "none"
                    content.style.display = "none"
                    content1.style.opacity = 1
                    content1.style.display = "flex"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "none"
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent >= 28 && percent < 35) {
                    let opacity = (percent / 14) - 1.5
                    video.style.display = "none"
                    content.style.display = "none"
                    content1.style.display = "none"
                    content2.style.display = "flex"
                    textContent2.style.opacity = 1
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "none"
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent >= 35 && percent < 42) {
                    let opacity = (percent / 14) - 2
                    textContent3.style.opacity = 1
                    video.style.display = "none"
                    content.style.display = "none"
                    content1.style.display = "none"
                    content2.style.display = "none"
                    content3.style.display = "flex"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "none"
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent >= 42 && percent < 49) {
                    let opacity = (percent / 14) - 2.5
                    textContent4.style.opacity = 1
                    video.style.display = "none"
                    content.style.display = "none"
                    content1.style.display = "none"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "flex"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "none"
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent >= 49 && percent < 56) {
                    let opacity = (percent / 14) - 3
                    textContent5.style.opacity = 1
                    video.style.display = "none"
                    content.style.display = "none"
                    content1.style.display = "none"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "flex"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "none"
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent >= 56 && percent < 63) {
                    let opacity = (percent / 14) - 3.5
                    textContent6.style.opacity = 1
                    video.style.display = "none"
                    content.style.display = "none"
                    content1.style.display = "none"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "flex"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "none"
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent >= 63 && percent < 70) {
                    let opacity = (percent / 14) - 3.5
                    textContent7.style.opacity = 1
                    video.style.display = "none"
                    content.style.display = "none"
                    content1.style.display = "none"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "flex"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "none"
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent >= 70 && percent < 77) {
                    let opacity = (percent / 14) - 4
                    textContent8.style.opacity = 1
                    video.style.display = "none"
                    content.style.display = "none"
                    content1.style.display = "none"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "flex"
                    content9.style.display = "none"
                    content10.style.display = "none"
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent >= 77 && percent < 84) {
                    let opacity = (percent / 14) - 4.5
                    textContent9.style.opacity = 1
                    video.style.display = "none"
                    content.style.display = "none"
                    content1.style.display = "none"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "flex"
                    content10.style.display = "none"
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent > 84 && percent <= 85) {
                    video.style.display = "none"
                    content.style.display = "none"
                    content1.style.display = "none"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "block"
                    content10.style.transform = 'scale(1)'
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent > 85 && percent <= 96) {
                    video.style.display = "none"
                    content.style.display = "none"
                    content1.style.display = "none"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "flex"
                    const scale = (percent - 85)
                    content10.style.transform = `scale(${scale})`
                    content10.style.opacity = 1
                    content10.style.transformOrigin = `68% 43%`
                    textAwaken.style.display = "none"
                    return;
                }
                if (percent > 97) {
                    video.style.display = "none"
                    content.style.display = "none"
                    content1.style.display = "none"
                    content2.style.display = "none"
                    content3.style.display = "none"
                    content4.style.display = "none"
                    content5.style.display = "none"
                    content6.style.display = "none"
                    content7.style.display = "none"
                    content8.style.display = "none"
                    content9.style.display = "none"
                    content10.style.display = "flex"
                    content10.style.transform = `scale(11)`
                    content10.style.transformOrigin = `68% 43%`
                    content10.style.opacity = 0
                    content10.style.transition = "opacity 0.5s"
                    textAwaken.style.display = "none"
                    return;
                }
            }
        })
        return () => {
            window.removeEventListener("scroll", ev => {
                console.log('unmount')
            })
        }
    }, [])


    return (
        <section className={classes.root} id={"textVideo"}>
            <div className={classes.text} id={"textAwaken"}>
                <span id={"scaleText"}>Awaken<br/>the World</span>
            </div>
            <div className={classes.boxSticky}>
                <video
                    id={'videoEarthTop'}
                    className={classes.video}
                    preload="auto" autoPlay loop muted
                    playsInline={true}
                    onLoadedData={(event) => {
                        event.preventDefault()
                        play()
                    }}
                >
                    <source src={`/videos/metavesity-${width}.mp4`} type='video/mp4'/>
                </video>
                <div className={classes.content} id={"contentTop"}>
                    <div className={"fadeInBottom"}>
                        <p className={classes.bigText}>人類の創造性が覚醒する</p>
                        <p className={`${classes.bigText} ${classes.marginBottom}`}>メタバースの共創</p>
                        <p className={classes.smallText}>※メタバースとは、メタ+ユニバースの合成語で、</p>
                        <p className={classes.smallText}> インターネット上の仮想世界を指します。</p>
                    </div>
                </div>

                {
                    EarthDatas.map((e, index) => {
                        return (
                            <div key={index} className={`${classes.imgRootContent}`} id={`earth-content-${e.id}`}>
                                <div className={classes.relative}>
                                    <img
                                        className={classes.imgRoot} src={e.image}
                                        alt={"innertours"}/>
                                    {
                                        e.id === 9 && <div>
                                            <div className={classes.textAbsolute1}>
                                                <div className={classes.textBottomAbsolute}>{e.textAbsolute11}</div>
                                                <div className={classes.textBottomAbsolute}>{e.textAbsolute12}</div>
                                            </div>
                                            <div className={classes.textAbsolute2}>
                                                <div className={classes.textBottomAbsolute}>{e.textAbsolute21}</div>
                                                <div className={classes.textBottomAbsolute}>{e.textAbsolute22}</div>
                                            </div>
                                            <div className={classes.textAbsolute3}>
                                                <div className={classes.textBottomAbsolute}>{e.textAbsolute31}</div>
                                                <div className={classes.textBottomAbsolute}>{e.textAbsolute32}</div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div
                                    className={`${e.id === 2 ? classes.textContent2 : classes.textContent} textContent-${e.id}`}
                                    id={`earth-textContent-${e.id}`}>
                                    <div className={"fadeInBottom"}>
                                        {
                                            e.textTop &&
                                            <div className={`${classes.textTop} earthTextTop`}>{e.textTop}</div>
                                        }
                                        {
                                            e.textSub &&
                                            <div className={`${classes.textSub} earthTextSub`}>{e.textSub}</div>
                                        }
                                        {
                                            e.textBottom1 &&
                                            <div className={`${classes.textBottom} earthTextBottom`}>{e.textBottom1}</div>
                                        }
                                        {
                                            e.textBottom2 && <div className={classes.textBottom}>{e.textBottom2}</div>
                                        }
                                        {
                                            e.textBottom3 && <div className={classes.textBottom}>{e.textBottom3}</div>
                                        }
                                        {
                                            e.textBottom4 && <div className={classes.textBottom}>{e.textBottom4}</div>
                                        }
                                        {
                                            e.textBottom5 && <div className={classes.textBottom}>{e.textBottom5}</div>
                                        }
                                        {
                                            e.textBottom6 && <div className={classes.textBottom}>{e.textBottom6}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

        </section>
    );
}

export default TextTop;
const useStyles = makeStyles(theme => ({
    root: {
        // background: "#222",
        position: "relative",
        height: "2000vh",
        zIndex: 3,
    },
    boxSticky: {
        position: "sticky",
        top: 0,
        height: "100vh",
        overflow: "hidden"
    },
    imgRootContent: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%", height: "101vh",
        zIndex: 3,
        background: "#fff",
        display: "none",
        alignItems: "center",
        justifyContent: "center",
        "& .textContent-9": {
            [theme.breakpoints.down("xs")]: {
                justifyContent: "flex-start",
                paddingTop: 50,
            },

        }
    },
    textContent: {
        top: 0,
        left: 0,
        right: 0,
        height: "100vh",
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        bottom: 0,
        textAlign: "left",
        justifyContent: "center",
        paddingLeft: 50,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 30,
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 15,
        }
    },
    textTop: {
        fontSize: 60,
        // marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            fontSize: 40,
            marginBottom: 15,
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 24
        }
    },

    textBottom: {
        fontSize: 40,
        [theme.breakpoints.down("sm")]: {
            fontSize: 24
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 18
        }
    },
    textBottomAbsolute: {
        fontSize: 40,
        [theme.breakpoints.down("sm")]: {
            fontSize: 21
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 14
        }
    },
    text: {
        zIndex: 27,
        // paddingTop: 50,
        fontSize: "150pt",
        fontFamily: "initial",
        fontWeight: 900,
        // WebkitTextStrokeWidth: "4px",
        WebkitTextStrokeColor: "#fff",
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "none",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "#000",
        color: "#fff",
        mixBlendMode: "multiply",
        "& span": {
            lineHeight: 0.9,
            transform: "scale(1)"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "40pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "30pt"
        }
    },
    video: {
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        width: "100%",
        height: "100vh",
        objectFit: "cover",
        backgroundColor: "#000",
        [theme.breakpoints.down("sm")]: {
            objectFit: "contain",
        },
        display: "none"
    },
    imgRoot: {
        position: 'relative',
        width: "100%",
        // objectFit: "contain",
        [theme.breakpoints.down("sm")]: {
            // objectFit: "contain",
        },
    },
    content: {
        zIndex: 3,
        color: "#fff",
        position: "absolute",
        top: "50%",
        left: 0,
        right: 0,
        transform: "translateY(-20%)",
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            transform: "translateY(-50%)",
            fontSize: "30pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "20pt"
        }
    },
    bigText: {
        fontSize: "60pt",
        margin: 0,
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            fontSize: "32pt"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "26pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "16pt"
        }
    },
    smallText: {
        fontSize: "40pt",
        margin: 0,
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            fontSize: "24pt"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "20pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px"
        }
    },
    marginBottom: {
        marginBottom: 40,
        [theme.breakpoints.down("sm")]: {
            marginBottom: 20,
        },
    },
    textContent2: {
        top: 0,
        left: 0,
        right: 0,
        height: "100vh",
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        bottom: 0,
        textAlign: "center",
        justifyContent: "flex-end",
        transform: "translateY(-100px)",
        "& .earthTextTop": {
            fontSize: 35,
            [theme.breakpoints.down("sm")]: {
                fontSize: 30
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: 20
            }
        },
        "& .earthTextSub": {
            fontSize: 30,
            [theme.breakpoints.down("sm")]: {
                fontSize: 20
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: 16
            }
        },
        "& .earthTextBottom": {
            fontSize: 60,
            [theme.breakpoints.down("sm")]: {
                fontSize: 50
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: 30
            }
        },

    },
    textAbsolute1: {
        position: "absolute",
        top: "24%",
        right: "28%",
        [theme.breakpoints.down("md")]: {
            right: "24%",
            top: "22%",
        },
    },
    textAbsolute2: {
        position: "absolute",
        top: "62%",
        right: "43%",
        [theme.breakpoints.down("md")]: {
            right: "36%",
            top: "60%",
        },
    },
    textAbsolute3: {
        position: "absolute",
        top: "62%",
        right: "12%",
        [theme.breakpoints.down("md")]: {
            right: "7%",
            top: "60%",
        },

    },
    relative: {
        position: "relative"
    }

}))