import React from 'react';
import {makeStyles} from '@material-ui/core/styles'
import {Grid, Hidden, Typography} from '@material-ui/core'

function InsightMapFacilitator(props) {

    const classes = useStyles()
    return (
        <div className={classes.container}>
            <div className={classes.root}>
                <div className={classes.content}>
                    <h2 className={classes.title}><span>インサイト・マップ ファシリテータ<br/>３ヶ月集中プログラム</span></h2>
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={12} md={5}>
                            <Typography className={classes.subTitle}>オンラインLive講座</Typography>
                            <Typography className={classes.subTitle}>12日間</Typography>
                            <div className={classes.containerBox1}>
                                <Grid container style={{
                                    borderRadius: "10px",
                                    backgroundColor: "#8A8A8A",
                                    color: "#fff",
                                    padding: "10px 0"
                                }}>
                                    <Grid item xs={4}>
                                        1ヶ月目<br/>
                                        基礎編
                                    </Grid>
                                    <Grid item xs={4}>2ヶ月目<br/>技術編</Grid>
                                    <Grid item xs={4}>3ヶ月目<br/>応用編</Grid>
                                </Grid>
                                <Grid container style={{padding: 10}} spacing={1}>
                                    <Grid item xs={4} style={{
                                        borderRadius: 10,
                                        position: "relative"
                                    }}>
                                        <img loading="lazy" style={{width: "100%", height: "100%"}} alt={'12day'}
                                             src={"/images/facilitator/step1.svg"}/>
                                        <div style={{
                                            position: "absolute",
                                            top: "25%",
                                            width: "100%",
                                            height: 5,
                                            zIndex: 2,
                                            background: "#fff"
                                        }}/>
                                        <div style={{
                                            position: "absolute",
                                            top: "50%",
                                            width: "100%",
                                            height: 5,
                                            zIndex: 2,
                                            background: "#fff"
                                        }}/>
                                        <div style={{
                                            position: "absolute",
                                            top: "75%",
                                            width: "100%",
                                            height: 5,
                                            zIndex: 2,
                                            background: "#fff"
                                        }}/>
                                        <div style={{position: "absolute", top: "10%", zIndex: 2, left: 15}}
                                             className={classes.day}>
                                            Day 1
                                        </div>
                                        <div style={{position: "absolute", top: "35%", zIndex: 2, right: 15}}
                                             className={classes.day}>
                                            Day 2
                                        </div>
                                        <div style={{position: "absolute", top: "60%", zIndex: 2, left: 15}}
                                             className={classes.day}>
                                            Day 3
                                        </div>
                                        <div style={{position: "absolute", top: "85%", zIndex: 2, right: 15}}
                                             className={classes.day}>
                                            Day 4
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div style={{
                                            height: "calc(50% - 5px)",
                                            backgroundColor: "#F1F1F1",
                                            width: "100%",
                                            borderRadius: "10px"
                                        }}>
                                            <div style={{width: "100%", padding: 5}}>
                                                <Typography className={classes.day}>Day 5,6</Typography>
                                                <img loading="lazy" alt={''} src={'/images/facilitator/day56.png'}
                                                     className={classes.box1Img}/>
                                                <Typography className={classes.day}>一致的な傾聴技術</Typography>
                                            </div>
                                        </div>
                                        <div style={{
                                            height: "calc(50% - 5px)",
                                            backgroundColor: "#F1F1F1",
                                            width: "100%",
                                            borderRadius: "10px",
                                            marginTop: 5
                                        }}>
                                            <div style={{width: "100%", padding: 5}}>
                                                <Typography className={classes.day}>Day 7,8</Typography>
                                                <img loading="lazy" alt={''} src={'/images/facilitator/day78.svg'}
                                                     className={classes.box1Img}/>
                                                <Typography className={classes.day}>深層心理の解析</Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div style={{
                                            height: "calc(50% - 5px)",
                                            backgroundColor: "#F1F1F1",
                                            width: "100%",
                                            borderRadius: "10px"
                                        }}>
                                            <div style={{width: "100%", padding: 5}}>
                                                <Typography className={classes.day}>Day 9,10</Typography>
                                                <img loading="lazy" alt={''} src={'/images/facilitator/day9.svg'}
                                                     className={classes.box1Img}/>
                                                <Typography className={classes.day}>グループワーク手法</Typography>
                                            </div>
                                        </div>
                                        <div style={{
                                            height: "calc(50% - 5px)",
                                            backgroundColor: "#F1F1F1",
                                            width: "100%",
                                            borderRadius: "10px",
                                            marginTop: 5
                                        }}>
                                            <div style={{width: "100%", padding: 5}}>
                                                <Typography className={classes.day}>Day 11,12</Typography>
                                                <img loading="lazy" alt={''} src={'/images/facilitator/day11.svg'}
                                                     className={classes.box1Img}/>
                                                <Typography className={classes.day}>独自プログラムの設計</Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>

                            </div>

                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Grid container style={{height: "100%"}}>
                                <Grid item xs={12} md={6}>
                                    <Hidden smDown>
                                        <div className={classes.subTitleBox}>
                                            <Typography className={classes.subTitle}>オンラインLive講座</Typography>
                                            <Typography className={classes.subTitle}> 1６0分×12回</Typography>
                                        </div>
                                    </Hidden>
                                    <div className={classes.plusContainer}>
                                        <Typography className={classes.plus}>+</Typography>
                                        <Hidden mdUp>
                                            <div className={classes.subTitleBox}>
                                                <Typography className={classes.subTitle}>オンライン1on1指導</Typography>
                                                <Typography className={classes.subTitle}>12日間</Typography>
                                            </div>
                                        </Hidden>
                                        {/*<Grid container direction={"row"} spacing={2} style={{width:"100%", height:"100%"}}>*/}
                                        {/*    <Grid item xs={6} md={12} className={classes.boxSize}>*/}
                                        {/*        <div style={{width: "100%", padding: 5}} >*/}
                                        {/*            <Typography className={classes.box2Text}>①1on1セッション</Typography>*/}
                                        {/*            <Typography className={classes.box2Text}>160分×6回</Typography>*/}
                                        {/*            <img loading="lazy" alt={''} src={'/images/facilitator/1on1_1.svg'}*/}
                                        {/*                 className={classes.box2Img}/>*/}
                                        {/*        </div>*/}
                                        {/*    </Grid>*/}
                                        {/*    <Grid item xs={6} md={12} className={classes.boxSize}>*/}
                                        {/*        <div style={{width: "100%", padding: 5}}>*/}
                                        {/*            <Typography className={classes.box2Text}>②1on1フィードバック</Typography>*/}
                                        {/*            <Typography className={classes.box2Text}>160分×6回</Typography>*/}
                                        {/*            <img loading="lazy" alt={''} src={'/images/facilitator/1on1_2.svg'}*/}
                                        {/*                 className={classes.box2Img2}/>*/}
                                        {/*        </div>*/}
                                        {/*    </Grid>*/}
                                        {/*</Grid>*/}
                                        <div className={classes.column}>
                                            <div className={classes.boxSize}>
                                                <div style={{width: "100%", padding: 5}}>
                                                    <Typography className={classes.box2Text}>①1on1セッション</Typography>
                                                    <Typography className={classes.box2Text}>160分×6回</Typography>
                                                    <img loading="lazy" alt={''} src={'/images/facilitator/1on1_1.svg'}
                                                         className={classes.box2Img}/>
                                                </div>
                                            </div>
                                            <div className={classes.boxSize2}>
                                                <div style={{width: "100%", padding: 5}}>
                                                    <Typography className={classes.box2Text}>②1on1フィードバック</Typography>
                                                    <Typography className={classes.box2Text}>160分×6回</Typography>
                                                    <img loading="lazy" alt={''} src={'/images/facilitator/1on1_2.svg'}
                                                         className={classes.box2Img2}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Hidden smDown>
                                        <div className={classes.subTitleBox}>
                                            <Typography className={classes.subTitle}>&nbsp;</Typography>
                                            <Typography className={classes.subTitle}>&nbsp;</Typography>
                                        </div>
                                    </Hidden>
                                    <div className={classes.plusContainer}>
                                        <Typography className={classes.plus}>+</Typography>
                                        <div className={classes.column}>
                                            <div className={classes.boxSize}>
                                                <div style={{width: "100%", padding: 5}}>
                                                    <Typography className={classes.box2Text}>WEBアプリケーション </Typography>
                                                    <Typography className={classes.box2Text}>1年間利用チケット</Typography>
                                                    <img loading="lazy" alt={''} src={'/images/facilitator/web.png'}
                                                         className={classes.box2Img}/>
                                                </div>
                                            </div>
                                            <div className={classes.boxSize2}>
                                                <div style={{width: "100%", padding: 5}}>
                                                    <Typography className={classes.box2Text}>ワークショップキット 一式</Typography>
                                                    <Typography className={classes.box2Text}>&nbsp;</Typography>
                                                    {/*<Typography className={classes.box2Text}>160分×6回</Typography>*/}
                                                    <img loading="lazy" alt={''}
                                                         src={'/images/facilitator/workshop.png'}
                                                         className={classes.box2Img2}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        height: "200vh", position: "relative",
        [theme.breakpoints.down('sm')]: {
            height: 'auto'
        },
    },
    root: {
        height: "100vh",
        position: "sticky",
        justifyContent: "center",
        display: 'flex',
        backgroundColor: "#F1F1F1",
        top: 0,
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            position: "relative",
        },
    },
    content: {
        width: "100%",
        display: 'flex',
        alignItems: 'center',
        // maxWidth:1200,
        // margin:'auto'
    },
    title: {
        margin: 0,
        textAlign: "center",
        fontSize: "36px",
        fontWeight: 900,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        "& span": {
            backgroundColor: " #fff",
            padding: "0 60px 20px 60px",
            borderRadius: "0 0 25px 25px",
            display: "inline-block",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            "& span": {
                padding: "0 25px 10px 25px",
                borderRadius: "0 0 25px 25px",
            },
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
            "& span": {
                padding: "0 25px 10px 25px",
                borderRadius: "0 0 25px 25px",
            },
        }
    },
    gridContainer: {
        margin: "auto",
        width: "calc(100% - 20px)",
        paddingTop: 50,
        [theme.breakpoints.down("sm")]: {
            marginTop: 10
        },
    },

    subTitle: {
        fontWeight: 700,
        fontSize: "15pt",
        color: "#2B3E60",
        [theme.breakpoints.down("xs")]: {
            fontSize: "12pt",
        }
    },
    plusContainer: {
        display: "flex",
        // justifyContent: "space-evenly",
        width: "100%",
        height: "calc(100% - 48px)",
        alignItems: "center",
        "& img": {
            maxWidth: "100%",

            height: "auto",
            maxHeight: "30vh"
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: 10,
            "& img": {
                maxHeight: "auto",
                maxWidth: "100%"
            },
            display: "block",
            height: "auto",
            marginBottom: 20
        },
        [theme.breakpoints.down("xs")]: {
            height: "auto",
            marginBottom: 20
        },
    },
    plus: {
        fontWeight: 700,
        fontSize: "30pt",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        lineHeight: 1,
        color: "#970505",
        width: 100,
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        },
    },
    containerBox1: {
        backgroundColor: "#fff",
        borderRadius: 10
    },
    day: {
        fontSize: "18px",
        fontWeight: 700,
        [theme.breakpoints.down("md")]: {
            fontSize: "10px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "18px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    box1Img: {
        width: "40%",
        margin: "5px auto",
        [theme.breakpoints.down("md")]: {
            width: "30%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "40%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "30%",
        },
    },
    box2Text: {
        fontSize: "18px",
        fontWeight: 700,
        [theme.breakpoints.down("md")]: {
            fontSize: "10px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "18px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    box2Img: {
        width: "40%",
        margin: "5px auto",
        [theme.breakpoints.down("md")]: {
            width: "40%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "40%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "50%",
        },
    },
    box2Img2: {
        width: "30%",
        margin: "5px auto",
        [theme.breakpoints.down("md")]: {
            width: "30%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "40%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "50%",
        },
    },
    subTitleBox: {
        [theme.breakpoints.down("xs")]: {
            marginTop: 10,
        },
    },
    boxSize: {
        height: "calc(50% - 5px)",
        backgroundColor: "#fff",
        width: "100%",
        borderRadius: "10px",
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            margin: "10px"
        },
        [theme.breakpoints.down("xs")]: {
            height: "auto",
            margin: "5px"
        },
    },
    boxSize2: {
        height: "calc(50% - 5px)",
        backgroundColor: "#fff",
        width: "100%",
        borderRadius: "10px",
        marginTop: 5,
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            margin: "10px"
        },
        [theme.breakpoints.down("xs")]: {
            height: "auto",
            margin: "5px"
        },
    },
    column: {
        width: "100%",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            display: "flex"
        },
    }

}))

export default InsightMapFacilitator;