import React from 'react';
import '@lottiefiles/lottie-player';
import {makeStyles} from "@material-ui/core";
import Part9 from "../Part9";
import BuyProducts from "../BuyProducts";
import {useMediaWidth} from "../../context/mediaQuery";
import Profile from "../Profile";
import Part10 from "../Part10";
import InsightMapFacilitator from "../InsightMapFacilitator";
import Instruction from "../Instruction/Instruction";
import TextTop from "../TextTop/TextTop";
import AwakenerAcademy from "../AwakenerAcademy/AwakenerAcademy";
import Part12 from "../Part12";


function Home(props) {
    const classes = useStyles()
    const width = useMediaWidth()
    console.log(width);
    const part4 = width === "xs" ? "/data/part4-mobile.json" : "/data/part4.json"
    const horseText = [
        {
            visibility: [0.00, 0.11],
            text: "<span style='color: #ffffff'>まずは、迷わず走れるように<br/>余計な視界を遮る<br/>ブリンカーが役に立ってきた。</span>"
        },
        {
            visibility: [0.11, 0.16],
            text: "<span style='color: #ffffff'>そして、目標のニンジン<br/>を得るために<br/>馬車馬として走ってきた。</span>"
        },
        {
            visibility: [0.16, 0.21],
            text: "<span style='color: #ffffff'>もちろん、それなりに<br/>得られる喜びはあった。</span>"
        },
        {
            visibility: [0.21, 0.26],
            text: "<span style='color: #ffffff'>でも、本当は<br/>いったいどこへ<br/>向かいたいのだろう？</span>"
        },
        {
            visibility: [0.26, 0.30],
            text: "ブリンカーを外すと<br/>広がる景色",
            // transform: "translateY(100px)"
        },
        {
            visibility: [0.30, 0.35],
            text: "何やらこの道の先に<br/>「成功の茂み」がある。"
        },
        {
            visibility: [0.35, 0.40],
            text: "ギャー！ヘビだ！<br/>怖い怖い。"
        },
        {
            visibility: [0.40, 0.45],
            text: "でも他の道を選ぼうにも<br/>「停滞の沼」は通りたくない。",
            // transform: "translateY(100px)"
        },
        {
            visibility: [0.45, 0.50],
            text: "でもよく見るとその先には、<br/>おいしそうなリンゴが。",
            // transform: "translateY(100px)"
        },
        {
            visibility: [0.50, 0.55],
            text: "そっかぁ。思い出したぞ。<br/>本当はニンジンよりも<br/>リンゴが大好きだったんだ。"
        },
        {
            visibility: [0.55, 0.60],
            text: "潜在的な<br/>意思決定シナリオを<br/>可視化できる<br/>インサイトマップ<br/>にマッピングしてみよう。"
        },
        {
            visibility: [0.60, 0.65],
            text: "どの道にも結局<br/>陰陽があるんだなぁ。<br/>まさに塞翁が馬だね。"
        },
        {
            visibility: [0.65, 0.70],
            text: "このままニンジンを<br/>得ていく人生もいいなぁ。<br/>あ！馬生か。"
        },
        {
            visibility: [0.70, 0.75],
            text: "でもヘビ、怖いなぁ。<br/>ここが<span style='color: #472AFC'>「推進による失敗」</span><br/>への恐れか。"
        },
        {
            visibility: [0.75, 0.80],
            text: "でも沼もイヤだなぁ。<br/>でもその先には、<br/>大好きなリンゴがある。<br/>ここが<span style='color: #FC9B15'>「停滞による成功」</span><br/>への望みか。"
        },
        {
            visibility: [0.80, 0.87],
            text: "ニンジンを得ていたい<br/><span style='color: #FC9B15'>「赤のベクトル」</span>と、<br/>ヘビを避け、リンゴへ向かう<br/><span style='color: #472AFC'>「青のベクトル」</span>が<br/>実は葛藤してたんだね。"
        },
    ];
    const textLeft = [
        {
            visibility: [0.55, 0.87],
            text: "Insight Map® <br/>白色＝陽　灰色＝陰"
        },
    ]

    return (
        <div className={'clf-home-page'}>
            {/*<MenuAppBar/>*/}
            <div className={classes.content}>
                <TextTop/>
                {/*<PopoverContent/>*/}
                <AwakenerAcademy/>
                {/*<Metaversity/>*/}
                <Part12/>
                {/*<CityIn/>*/}
                {/*<VideoTop2 id={"clf-video-top-scroll"}/>*/}
                {/*<VideoTop id={"clf-video-top-scroll"}/>*/}
                {/*<TextTop/>*/}
                {/*<UnconsciousSelfContradiction/>*/}
                {/*<PlayBodyMovin path={"/data/part1.json"} height={"300vh"}/>*/}
                {/*<Horse/>*/}
                {/*/!*<HorseEye/>*!/*/}
                {/*<PlayBodyMovin path={part4} height={"1500vh"}*/}
                {/*               playText={horseText}*/}
                {/*               textLeft={textLeft}*/}
                {/*               marginTop={"-100vh"}*/}
                {/*/>*/}
                {/*<InsightMap/>*/}

                {/*<InsightMapTree/>*/}
                {/*<img loading="lazy" style={{width: "100%", height: "100%", maxWidth: "1200px", margin: "auto"}}*/}
                {/*     src={'/images/innertours_map.png'} alt={"innertours"}/>*/}
                {/*<UnifyTheInside/>*/}
                {/*<Awakeners/>*/}
                <Part9/>
                {/*<CityOut/>*/}

                {/*<Part12/>*/}

                <Part10/>
                <Profile/>
                <Instruction/>
                <InsightMapFacilitator/>
                <BuyProducts/>

            </div>

        </div>
    );
}

export default Home;
const useStyles = makeStyles(theme => ({
    content: {
        // margin: "16px 27px",
        fontWeight: 900,
        [theme.breakpoints.down('sm')]: {
            margin: "unset",
        }
    }
}))