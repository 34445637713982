import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

function BuyProducts(props) {
    const classes = useStyles()

    const handlePurcharse = () => {
        const productId = process.env.REACT_APP_ENV === "production" ? "コーデミア-1628853875611" : "コーデミア-1628853126051"
        window.location.assign(`${process.env.REACT_APP_CART_GENIAM}/?type=packages&num=1&add=${productId}`)
    }

    return (
        <div className={classes.root}>
            <div className={classes.priceContainer}>
                <Typography className={classes.priceText + " " + classes.priceColor}>コーデミア<br/>年間パスポート</Typography>
                {/*<Typography className={classes.priceText + " " + classes.priceColor}>金額を値上げして</Typography>*/}
                <Typography className={classes.priceText + " " + classes.priceColor}>99,800円<br/>
                    （税込み109,780円）</Typography>
            </div>
            <div className={classes.btn}>
                <button className={`${classes.btnSize} clearfix`}
                        onClick={handlePurcharse}
                >
                    <div className={classes.cartIcon}>
                        <img loading="lazy" src={"/images/Asset 13.svg"} alt={''}/>
                    </div>

                    <div className={classes.cartContent}
                         style={{backgroundImage: "url(/images/btn4.svg)", backgroundSize: "cover"}}>
                        <Typography className={`${classes.btnText} ${classes.bold}`}
                                    style={{color: "white"}}>今すぐ申し込む</Typography>
                    </div>

                </button>
            </div>
            <div className={classes.payRoot}>
                <span className={classes.payText}>
                    銀行振込
                </span>
                <img loading="lazy" alt={''} src={"/images/paymethods.png"} className={classes.payImg}/>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    payRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btnText: {
        fontSize: "40px",
        display: "block",
        textAlign: "center",
        width: "100%",
        [theme.breakpoints.down("lg")]: {
            fontSize: "35px",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "25px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "15px",
        }
    },
    bold: {
        fontWeight: "600 !important"
    },
    btnSize: {
        width: "100%",
        margin: 0, border: "5px solid black", padding: 0,
        minWidth: "180px",
        // [theme.breakpoints.down("sm")]: {
        //     maxWidth: 126,
        // },
    },
    btn: {
        margin: "auto",
        width: "50%",
        maxWidth: '500px',
        [theme.breakpoints.down("sm")]: {
            width: "300px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "200px",
        },
        "& :hover": {
            opacity: 0.8,
            transition: '1s all'
        },
    },
    cartContent: {
        marginLeft: 100,
        height: 100,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.down("lg")]: {
            marginLeft: 80,
            height: 80,
        },
        [theme.breakpoints.down("md")]: {
            marginLeft: 70,
            height: 70,
        },
        [theme.breakpoints.down("xs")]: {
            marginLeft: 50,
            height: 50,
        },
        cursor: 'pointer',
    },
    cartIcon: {
        width: 100,
        height: 100,
        background: "#fff",
        position: "relative",
        float: "left",
        cursor: 'pointer',
        "& img": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%"
        },
        [theme.breakpoints.down("lg")]: {
            width: 80,
            height: 80,
        },
        [theme.breakpoints.down("md")]: {
            width: 70,
            height: 70,
        },
        [theme.breakpoints.down("xs")]: {
            width: 50,
            height: 50,
        }
    },
    subOneYear: {
        fontWeight: "bold",
        fontSize: "20px"
    },
    priceText: {
        fontWeight: "bold",
        fontSize: "50px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "30px",
        }
    },
    title: {
        fontWeight: "bold",
        fontSize: "30px",
        "& span": {
            fontSize: "40px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "15px",
            "& span": {
                fontSize: "25px",
            },

        }
    },
    subText: {
        // fontWeight:"bold",
        fontSize: "15px",
        marginTop: 5,
        marginBottom: 5,
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "7px",
        }
    },
    payImg: {
        width: "700px",
        marginLeft: 20,
        [theme.breakpoints.down("sm")]: {
            width: "70%",
        },
    },
    payText: {
        fontSize: "20px",
        marginTop: 5,
        marginBottom: 5,
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "8px",
        }
    },
    imgAndom: {
        width: "150px",
        margin: "20px auto",
        [theme.breakpoints.down("sm")]: {
            width: "120px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "70px",
        }
    },
    root: {
        textAlign: "center",
        margin: "50px 0"
    },
    product: {
        margin: "50px auto",

    },
    productTitle: {
        fontWeight: "bold",
        fontSize: "25px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "20px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
        }
    },
    Wisdom: {
        fontWeight: "bold",
        fontSize: "40px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "35px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "15px",
        }
    },
    productContent: {
        fontWeight: "bold",
        fontSize: "30px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "25px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
        }
    },
    companyName: {
        fontWeight: "bold",
        fontSize: "50pt",
        letterSpacing: "2px",
        lineHeight: 1,
        [theme.breakpoints.down("sm")]: {
            fontSize: "35pt",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "20pt",
        }
    },
    subCompany: {
        fontWeight: "bold",
        fontSize: "19pt",
        letterSpacing: "2px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "13pt",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "7pt",
        }
    },
    twoYearPlan: {
        margin: "30px auto"
    },
    priceColor: {
        color: "#EA5515"
    },
    priceDividedColor: {
        color: "#22AC39"
    },
    priceContainer: {
        width: "95%",
        maxWidth: "1600px",
        padding: 5,
        margin: "auto",
        // marginTop: "50px"
    },
    price: {
        "& p": {
            position: "relative",
            display: "inline-block",
            margin: 0,
        },
        "& span": {
            position: "absolute",
            right: 7,
            top: 0,
            fontSize: "35px",
            color: "#EA5515",
            transform: "rotate(90deg)",
            [theme.breakpoints.down("xs")]: {
                fontSize: "17px",
                right: 5,
            }

        }
    },
    iconArrow: {
        fontSize: "55px",
        color: "#EA5515",
        transform: "rotate(90deg)",
        [theme.breakpoints.down("xs")]: {
            fontSize: "40px",
        }
    }
}))

export default BuyProducts;
