import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import PopoverContent from "../PopoverContent/PopoverContent";

const poppers = [
    {
        content: "<a href='https://metamors.geniam.com/insight-map' target='_blank'>詳細はこちら</a>",
        title: "",
        month: "10 月",
        monthColor: "#F8C375",
        id: 1,
        img: "/images/city/Asset 16@2x.png",
        sub: "インサイトマップ®"
    },
    {
        content: "ああああああああ<br/>あああああああ",
        title: "",
        month: "11 月",
        monthColor: "#F69B7C",
        id: 2,
        img: "/images/city/Asset 17@2x.png",
        sub: "インサイトマップ®"
    },
    {
        content: "ああああああああ<br/>あああああああ",
        title: "",
        month: "12 月",
        monthColor: "#EF847A",
        id: 3,
        img: "/images/city/Asset 18@2x.png",
        sub: "インナーモデル"

    },
    {
        content: "ああああああああ<br/>あああああああ",
        title: "",
        month: "1 月",
        monthColor: "#D8718B",
        id: 4,
        img: "/images/city/Asset 19@2x.png",
        sub: "インサイトマップワン"
    },
    {
        content: "ああああああああ<br/>あああああああ",
        title: "",
        month: "2 月",
        monthColor: "#B289BF",
        id: 5,
        img: "/images/city/Asset 21@2x.png",
        sub: "ユニファイ・インサイト・ユアセルフ"
    },
    {
        content: "ああああああああ<br/>あああああああ",
        title: "",
        month: "3 月",
        monthColor: "#9284BB",
        id: 6,
        img: "/images/city/Asset 22@2x.png",
        sub: "ディアミング"
    },
    {
        content: "ああああああああ<br/>あああああああ",
        title: "",
        month: "4 月",
        monthColor: "#8D9FCA",
        id: 7,
        img: "/images/city/Asset 23@2x.png",
        sub: "エネカラー®"
    },
    {
        content: "ああああああああ<br/>あああああああ",
        title: "",
        month: "5 月",
        monthColor: "#78C0E0",
        id: 8,
        img: "/images/city/Asset 24@2x.png",
        sub: "エネカラー®"
    },
    {
        content: "ああああああああ<br/>あああああああ",
        title: "",
        month: "6 月",
        monthColor: "#A5D090",
        id: 9,
        img: "/images/city/Asset 25@2x.png",
        sub: "エネボイス"
    },
    {
        content: "ああああああああ<br/>あああああああ",
        title: "",
        month: "7 月",
        monthColor: "#E1EA8A",
        id: 10,
        img: "/images/city/Asset 26@2x.png",
        sub: "アンディング™"
    },
    {
        content: "ああああああああ<br/>あああああああ",
        title: "",
        month: "8 月",
        monthColor: "#F9F386",
        id: 11,
        img: "/images/city/Asset 27@2x.png",
        sub: "アイデンティティ×ポジション"
    },
    {
        content: "ああああああああ<br/>あああああああ",
        title: "",
        month: "9 月",
        monthColor: "#F9D98A",
        id: 12,
        img: "/images/city/Asset 28@2x.png",
        sub: "ソアリング"
    },
]

const months = [
    {
        id: 1,
        img: "month1@2x.png"
    },
    {
        id: 2,
        img: "month2@2x.png"
    },
    {
        id: 3,
        img: "month3@2x.png"
    },
    {
        id: 4,
        img: "month4@2x.png"
    },
    {
        id: 5,
        img: "month5@2x.png"
    },
    {
        id: 6,
        img: "month6@2x.png"
    },
    {
        id: 7,
        img: "month7@2x.png"
    },
    {
        id: 8,
        img: "month8@2x.png"
    },
    {
        id: 9,
        img: "month9@2x.png"
    },
    {
        id: 10,
        img: "month10@2x.png"
    },
    {
        id: 11,
        img: "month11@2x.png"
    },
    {
        id: 12,
        img: "month12@2x.png"
    },
]

function Part12(props) {
    const classes = useStyles()
    const displayPopper = (id) => {
        poppers.forEach(item => {
            const element = document.getElementById('part12-popper-' + item.id)
            element.style.opacity = item.id === id ? 1 : 0
            element.style.display = item.id === id ? "block" : 'none'
        })
    }
    const displayMonth = (id) => {
        months.forEach(item => {
            const element = document.getElementById('part12-month-' + item.id)
            element.style.opacity = item.id === id ? 1 : 0
            element.style.display = item.id === id ? "block" : 'none'
        })
    }
    useEffect(() => {
        window.addEventListener('scroll', ev => {
            const el = document.getElementById('city-in-2')
            if (!el) return;
            const total = el?.offsetHeight - window.innerHeight
            const start = el?.offsetTop + window.innerHeight
            const screenHeight = window.innerHeight
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight
                if (scrollItem > scrollValid) {
                    return;
                }
                const percent = Math.round(scrollItem / scrollValid * 100)
                // console.log(percent);
                const img1 = document.getElementById('city-zoom-2')
                const img2 = document.getElementById('city-zoom-3')
                const img3 = document.getElementById('city-zoom-4')
                const img4 = document.getElementById('city-zoom-5')
                const img5 = document.getElementById('city-zoom-6')
                const img6 = document.getElementById('city-zoom-7')
                const rabbit = document.getElementById('rabbit-2')
                // const blackBg = document.getElementById('part12-bg-black')
                // const video = document.getElementById('part12-city-in-video')
                let imgWidth = img1.offsetWidth
                const height = img1.offsetHeight
                if (height * 1.2 < imgWidth) {
                    imgWidth = height * 1.2
                }
                const part12_popper_1 = document.getElementById('part12-popper-1')
                const part12_popper_2 = document.getElementById('part12-popper-2')
                const part12_popper_3 = document.getElementById('part12-popper-3')
                const part12_popper_4 = document.getElementById('part12-popper-4')
                const part12_popper_6 = document.getElementById('part12-popper-6')
                const part12_popper_7 = document.getElementById('part12-popper-7')
                const part12_popper_8 = document.getElementById('part12-popper-8')
                const part12_popper_9 = document.getElementById('part12-popper-9')
                const part12_popper_12 = document.getElementById('part12-popper-12')
                if (percent < 5) {
                    img1.style.opacity = 1
                    img2.style.opacity = 0
                    img3.style.opacity = 0
                    img4.style.opacity = 0
                    img5.style.opacity = 0
                    img6.style.opacity = 0
                    rabbit.style.opacity = 0
                    rabbit.style.transform = 'translate(0px,0px)'
                    displayPopper(0)
                    displayMonth(0)
                }
                if (percent >= 5 && percent <= 12) {
                    img1.style.opacity = 0
                    img2.style.opacity = 1
                    img3.style.opacity = 0
                    img4.style.opacity = 0
                    img5.style.opacity = 0
                    img6.style.opacity = 0
                    rabbit.style.opacity = 1
                    rabbit.style.transform = 'translate(0px,0px)'
                    part12_popper_1.style.transform = 'translateX(-40%)'
                    displayPopper(1)
                    displayMonth(1)
                }
                if (percent > 12 && percent <= 19) {
                    part12_popper_2.style.transform = 'translateX(-40%)'
                    img1.style.opacity = 0
                    img2.style.opacity = 1
                    img3.style.opacity = 0
                    img4.style.opacity = 0
                    img5.style.opacity = 0
                    img6.style.opacity = 0
                    rabbit.style.opacity = 1
                    rabbit.style.transform = 'translate(0px,0px)'
                    displayPopper(2)
                    displayMonth(2)
                }
                if (percent > 19 && percent <= 27) {
                    part12_popper_3.style.transform = 'translateX(-70%)'
                    img1.style.opacity = 0
                    img2.style.opacity = 0
                    img3.style.opacity = 1
                    img4.style.opacity = 0
                    img5.style.opacity = 0
                    img6.style.opacity = 0
                    rabbit.style.opacity = 1
                    rabbit.style.transform = `translate(${imgWidth * 0.2}px,${height * 0.2}px)`
                    displayPopper(3)
                    displayMonth(3)
                }
                if (percent > 27 && percent <= 35) {
                    part12_popper_4.style.transform = 'translateX(-35%)'
                    img1.style.opacity = 0
                    img2.style.opacity = 0
                    img3.style.opacity = 0
                    img4.style.opacity = 1
                    img5.style.opacity = 0
                    img6.style.opacity = 0
                    rabbit.style.opacity = 1
                    rabbit.style.transform = `translate(-${imgWidth * 0.13}px,-${height * 0.2}px)`
                    displayPopper(4)
                    displayMonth(4)
                }
                if (percent > 35 && percent <= 43) {

                    img1.style.opacity = 0
                    img2.style.opacity = 0
                    img3.style.opacity = 0
                    img4.style.opacity = 0
                    img5.style.opacity = 1
                    img6.style.opacity = 0
                    rabbit.style.opacity = 1
                    rabbit.style.transform = `translate(-${imgWidth * 0.3}px,-${height * 0.1}px)`
                    displayPopper(5)
                    displayMonth(5)
                }
                if (percent > 43 && percent <= 51) {
                    part12_popper_6.style.transform = 'translateX(-70%)'
                    img1.style.opacity = 0
                    img2.style.opacity = 0
                    img3.style.opacity = 0
                    img4.style.opacity = 0
                    img5.style.opacity = 1
                    img6.style.opacity = 0
                    rabbit.style.opacity = 1
                    rabbit.style.transform = `translate(${imgWidth * 0.2}px,${height * 0.2}px)`
                    displayPopper(6)
                    displayMonth(6)
                }
                if (percent > 51 && percent <= 59) {
                    part12_popper_7.style.transform = 'translateX(-70%)'
                    img1.style.opacity = 0
                    img2.style.opacity = 0
                    img3.style.opacity = 0
                    img4.style.opacity = 0
                    img5.style.opacity = 0
                    img6.style.opacity = 1
                    rabbit.style.opacity = 1
                    rabbit.style.transform = `translate(${imgWidth * 0.35}px,-${height * 0.05}px)`
                    displayPopper(7)
                    displayMonth(7)
                }
                if (percent > 59 && percent <= 67) {
                    part12_popper_8.style.transform = 'translateX(-70%)'
                    img1.style.opacity = 0
                    img2.style.opacity = 0
                    img3.style.opacity = 0
                    img4.style.opacity = 0
                    img5.style.opacity = 0
                    img6.style.opacity = 1
                    rabbit.style.opacity = 1
                    rabbit.style.transform = `translate(${imgWidth * 0.35}px,-${height * 0.05}px)`
                    displayPopper(8)
                    displayMonth(8)
                }
                if (percent > 67 && percent <= 75) {
                    part12_popper_9.style.transform = 'translateX(-70%)'
                    img1.style.opacity = 0
                    img2.style.opacity = 0
                    img3.style.opacity = 0
                    img4.style.opacity = 0
                    img5.style.opacity = 0
                    img6.style.opacity = 1
                    rabbit.style.opacity = 1
                    rabbit.style.transform = `translate(${imgWidth * 0.2}px,${height * 0.2}px)`
                    displayPopper(9)
                    displayMonth(9)
                }
                if (percent > 75 && percent <= 83) {

                    img1.style.opacity = 0
                    img2.style.opacity = 0
                    img3.style.opacity = 0
                    img4.style.opacity = 0
                    img5.style.opacity = 0
                    img6.style.opacity = 1
                    rabbit.style.opacity = 1
                    rabbit.style.transform = `translate(-${imgWidth * 0.3}px,-${height * 0.1}px)`
                    displayPopper(10)
                    displayMonth(10)
                }
                if (percent > 83 && percent <= 91) {

                    img1.style.opacity = 0
                    img2.style.opacity = 0
                    img3.style.opacity = 0
                    img4.style.opacity = 0
                    img5.style.opacity = 0
                    img6.style.opacity = 1
                    rabbit.style.opacity = 1
                    rabbit.style.transform = `translate(-${imgWidth * 0.3}px,-${height * 0.1}px)`
                    displayPopper(11)
                    displayMonth(11)
                }
                if (percent > 91) {
                    part12_popper_12.style.transform = 'translateX(-70%)'
                    img1.style.opacity = 0
                    img2.style.opacity = 0
                    img3.style.opacity = 0
                    img4.style.opacity = 0
                    img5.style.opacity = 0
                    img6.style.opacity = 1
                    rabbit.style.opacity = 1
                    rabbit.style.transform = `translate(${imgWidth * 0.2}px,${height * 0.2}px)`
                    displayPopper(12)
                    displayMonth(12)
                }
            }
        })
        return () => {
            window.removeEventListener("scroll", ev => {
                console.log('unmount')
            })
        }
    }, [])
    return (
        <div className={classes.root} id={'city-in-2'}>
            <div className={classes.content}>
                <img loading="lazy" className={classes.cityImg} id={'city-zoom-2'} src={'/images/city/map.png'}
                     alt={'city2'}/>

                <img loading="lazy" className={classes.cityImg} id={'city-zoom-3'} src={'/images/city/map2.png'}
                     alt={'city2'}/>
                <img loading="lazy" className={classes.cityImg} id={'city-zoom-4'} src={'/images/city/map3.png'}
                     alt={'city2'}/>

                <img loading="lazy" className={classes.cityImg} id={'city-zoom-5'} src={'/images/city/map4.png'}
                     alt={'city2'}/>

                <img loading="lazy" className={classes.cityImg} id={'city-zoom-6'} src={'/images/city/map5.png'}
                     alt={'city2'}/>

                <img loading="lazy" className={classes.cityImg} id={'city-zoom-7'} src={'/images/city/map6.png'}
                     alt={'city2'}/>
                <div className={classes.rabbit} id={'rabbit-2'}>
                    <div style={{position: "relative", paddingTop: 15}}>
                        <div className={classes.arrowDown}>
                            <div className={classes.arrowDown1}/>
                            <div className={classes.arrowDown2}/>
                        </div>
                        <img loading="lazy" src={'/images/city/tho.png'} alt={'rabbit'}/>
                        {
                            poppers.map(item => {
                                return (
                                    <div className={classes.popper} id={`part12-popper-${item.id}`} key={item.id}>
                                        <PopoverContent item={item}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    months.map(item => {
                        return (
                            <div className={classes.twopic} id={`part12-month-${item.id}`}>
                                <img loading="lazy" alt={'pic'} src={`/images/city/${item.img}`}/>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: "2000vh",
        position: "relative",
        marginTop: "-100vh",
        // zIndex: -2
    },
    content: {
        position: "sticky",
        top: 0,
        overflow: "hidden",
        height: "100vh",
        display: "flex",
        alignItems: "center"
    },
    cityImg: {
        width: '100%',
        transition: "all 0.5s",
        margin: "auto",
        maxHeight: "100vh",
        position: "absolute",
        left: 0,
        right: 0,
        objectFit: "contain"
    },
    rabbit: {
        position: "absolute",
        zIndex: 1,
        top: "45%",
        left: "45%",
        transform: "translate(-50%, -50%)",
        "& img": {
            width: "100px",
            [theme.breakpoints.down('sm')]: {
                width: "70px"
            },
            [theme.breakpoints.down('xs')]: {
                width: "50px"
            }
        },
        transition: "all 0.25s",
        opacity: 0
    },
    bgBlack: {
        background: "#000",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1,
        opacity: 0
    },

    video: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 2,
        opacity: 0
    },
    twopic: {
        position: "absolute",
        bottom: 50,
        left: 20,
        zIndex: 2,
        "& img": {
            height: "100px",
            [theme.breakpoints.down('sm')]: {
                height: 70
            },
            [theme.breakpoints.down('xs')]: {
                // width: 50
                height: 50
            }
        },
        opacity: 0
    },
    popper: {
        position: 'absolute',
        bottom: "100%",
        marginBottom: -3,
        left: "-15px",
        // transform: "translate(-50%,-50%)",
        opacity: 0,
        transition: "all 0.5s",
        [theme.breakpoints.down('sm')]: {
            // top: "-80%",
        },
        [theme.breakpoints.down('xs')]: {
            // top: "-100%",
        },
    },
    arrowDown1: {
        width: 0,
        height: 0,
        borderLeft: "20px solid transparent",
        borderRight: "20px solid transparent",
        borderTop: "20px solid #fff",
        position: "absolute",
        top: "100%",
        marginTop: "0px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 2
    },
    arrowDown2: {
        width: 0,
        height: 0,
        borderLeft: "21px solid transparent",
        borderRight: "21px solid transparent",
        borderTop: "21px solid #000",
        position: "absolute",
        top: "100%",
        marginTop: "0px",
        left: "50%",
        transform: "translateX(-50%)",
    },
    arrowDown: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "100%"
    }
}));

export default Part12;