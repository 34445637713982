import React from 'react';
import {Grid, makeStyles} from "@material-ui/core";

function PopoverContent({item}) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.title} id={'part12-popper-title'}>タイトル</div>
            <span id={'part12-popper-month'} className={classes.month}
                  style={{color: item.monthColor}}> {item.month}</span>
            <Grid container>
                <Grid item xs={6}>
                    <div>
                        <img loading="lazy" id={'part12-popper-img'} className={classes.img} src={item.img}
                             alt=""/>
                    </div>
                    <span className={classes.sub}>{item.sub}</span>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.content} dangerouslySetInnerHTML={{__html: item.content}}>
                    </div>
                </Grid>
            </Grid>
            {/*<div className={classes.arrowDown1}/>*/}
            {/*<div className={classes.arrowDown2}/>*/}
        </div>
    );
}

export default PopoverContent;
const useStyles = makeStyles(theme => ({
    root: {
        width: 600,
        border: "solid 2px #000",
        borderRadius: "15px",
        position: "relative",
        backgroundColor: "#fff",
        padding: 10,
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            width: "300px"
        },
        [theme.breakpoints.down('xs')]: {
            width: "250px"
        }
    },
    title: {
        fontSize: "18pt",
        fontWeight: "bold",
        margin: 0,
        padding: "5px 0",
        [theme.breakpoints.down('sm')]: {
            fontSize: "10pt",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "8pt",
        },
    },
    img: {
        width: "100%",
        height: "100px",
        boxSizing: "border-box",
        objectFit: "contain",
        padding: 5,
        [theme.breakpoints.down('sm')]: {
            height: 50,
        },
        [theme.breakpoints.down('xs')]: {
            height: 40,
        },
    },
    content: {
        boxSizing: "border-box",
        padding: 5,
        lineHeight: 1.5,
        fontSize: "16px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px"
        },

        [theme.breakpoints.down('xs')]: {
            fontSize: "12px",
        },
        // "& a": {
        //     textDecoration: "none",
        //     color: "#000"
        // }
    },
    arrowDown1: {
        width: 0,
        height: 0,
        borderLeft: "20px solid transparent",
        borderRight: "20px solid transparent",
        borderTop: "20px solid #fff",
        position: "absolute",
        top: "100%",
        marginTop: "0px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 2
    },
    arrowDown2: {
        width: 0,
        height: 0,
        borderLeft: "21px solid transparent",
        borderRight: "21px solid transparent",
        borderTop: "21px solid #000",
        position: "absolute",
        top: "100%",
        marginTop: "0px",
        left: "50%",
        transform: "translateX(-50%)",
    },
    month: {
        position: "absolute",
        top: 10,
        right: 10,
        fontWeight: 700,
        fontSize: 15,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        }
    },
    sub: {
        fontSize: 10,
        lineHeight: 1.4,
        [theme.breakpoints.down('sm')]: {
            fontSize: 8,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 6,
        },
    }
}))